var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", {
    staticClass: "red",
    attrs: { href: "mailto:" + _vm.data },
    domProps: { textContent: _vm._s(_vm.data) },
    on: {
      click: function ($event) {
        $event.stopPropagation()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }